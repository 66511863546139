import React from "react"
import { FaFacebookMessenger } from "@react-icons/all-files/fa/FaFacebookMessenger"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaSnapchatGhost } from "@react-icons/all-files/fa/FaSnapchatGhost"
import { FaTelegramPlane } from "@react-icons/all-files/fa/FaTelegramPlane"
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"

const SocialiCons = () => {
  return (
    <div className="absolute left-0 bottom-0 px-5 py-0 text-[#1e90ffcc] md:px-8 md:py-7">
      <a
        className="inline-block h-10 w-10 transform cursor-pointer rounded-full p-[10px] text-lg text-white transition duration-700 ease-out hover:-translate-y-1 hover:bg-[#ff6347] hover:shadow-xl hover:ease-in"
        href="https://twitter.com/mdfurkanulislam"
        target="_blank"
      >
        <FaTwitter />
      </a>
      <a
        className="inline-block h-10 w-10 transform cursor-pointer rounded-full p-[10px] text-lg text-white transition duration-700 ease-out hover:-translate-y-1 hover:bg-[#ff6347] hover:shadow-xl hover:ease-in"
        href="https://www.instagram.com/mdfurkanislam/"
        target="_blank"
      >
        <FaInstagram />
      </a>
      <a
        className="inline-block h-10 w-10 transform cursor-pointer rounded-full p-[10px] text-lg text-white transition duration-700 ease-out hover:-translate-y-1 hover:bg-[#ff6347] hover:shadow-xl hover:ease-in"
        href="https://fb.com/furkanulisl"
        target="_blank"
      >
        <FaFacebookMessenger />
      </a>

      <a
        className="inline-block h-10 w-10 transform cursor-pointer rounded-full p-[10px] text-lg text-white transition duration-700 ease-out hover:-translate-y-1 hover:bg-[#ff6347] hover:shadow-xl hover:ease-in"
        href="https://wa.me/46729495803"
        target="_blank"
      >
        <FaWhatsapp />
      </a>
      <a
        className="inline-block h-10 w-10 transform cursor-pointer rounded-full p-[10px] text-lg text-white transition duration-700 ease-out hover:-translate-y-1 hover:bg-[#ff6347] hover:shadow-xl hover:ease-in"
        href="https://t.me/mdfurkanisl"
        target="_blank"
      >
        <FaTelegramPlane />
      </a>
      <a
        className="inline-block h-10 w-10 transform cursor-pointer rounded-full p-[10px] text-lg text-white transition duration-700 ease-out hover:-translate-y-1 hover:bg-[#ff6347] hover:shadow-xl hover:ease-in"
        href="https://www.snapchat.com/add/furkanulisl"
        target="_blank"
      >
        <FaSnapchatGhost />
      </a>
    </div>
  )
}

export default SocialiCons
