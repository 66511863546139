import React from "react"
import Layout from "../components/Layout"
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import SocialiCons from "../components/SocialiCons"
import ContactForm from "../components/ContactForm"
import { SEO } from "../components/Seo"

const contact = () => {
  return (
    <>
      <Layout>
        <main className="container mx-auto mb-5 flex flex-col pb-5 md:my-10 md:max-w-5xl md:pb-20">
          <div className="w-full py-10 text-center">
            <h1 className="text-4xl font-bold text-black ">Get in touch</h1>
            <p className="p-5 text-center text-xl">
              Any question in your mind? Just drop your message!
            </p>
          </div>
          <div className="flex w-full flex-col rounded-xl p-1 text-center  shadow-md md:flex-row md:bg-white">
            <div className="relative mx-auto min-h-[270px] w-full overflow-hidden rounded-xl bg-button px-5 py-5 text-left  shadow-md md:basis-2/5 md:px-8">
              <h1 className="mt-0 text-center text-3xl font-bold text-white md:text-left">
                Contact Information
              </h1>
              <p className="mt-2 max-w-md text-center text-sm text-white md:text-left">
                Choose Your option to reach out. To get faster response contact
                with mobile or IM.
              </p>
              <div className="mt-2 md:mt-5">
                <a
                  className="text-lg transition duration-700 hover:text-white hover:opacity-50"
                  href="tel:+46729495803"
                >
                  <FaPhoneAlt className="inline text-[#ff6347]" />
                  <p className="ml-5 inline text-white">+46729495803</p>
                </a>
              </div>
              <div className="mt-2 md:mt-5">
                <a
                  className="text-lg transition duration-700 hover:text-white hover:opacity-50"
                  href="mailto:md@furkanul.com"
                >
                  <FaEnvelope className="inline text-[#ff6347]" />
                  <p className="ml-5 inline text-white">md@furkanul.com</p>
                </a>
              </div>
              <div className="mt-2 md:mt-5">
                <a
                  className="text-lg transition duration-700 hover:text-white hover:opacity-50"
                  href="https://goo.gl/maps/3Ft5yJQ4umMmnGx8A"
                  target="_blank"
                >
                  <FaMapMarkerAlt className="inline text-[#ff6347]" />
                  <p className="ml-5 inline text-white">Örebro, Sweden.</p>
                </a>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="absolute -right-20 -bottom-20 text-[#ff6347]"
                viewBox="0 0 16 16"
                height="200"
                width="200"
              >
                <circle cx="8" cy="8" r="8"></circle>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="absolute -right-12 -bottom-12 text-[#1e90ffcc]"
                viewBox="0 0 16 16"
                height="140"
                width="140"
              >
                <circle cx="8" cy="8" r="8"></circle>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="absolute -right-10 -bottom-10 text-[#1eceff82]"
                viewBox="0 0 16 16"
                height="110"
                width="110"
              >
                <circle cx="8" cy="8" r="8"></circle>
              </svg>
              <SocialiCons />
            </div>
            <div className="w-full pt-10 text-left md:basis-3/5 md:p-12">
              <ContactForm />
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default contact

export const Head = () => (
  <SEO
    title="Contact"
    description="Choose Your option to reach out. To get faster response contact with mobile or IM."
    pathname="contact"
    children={undefined}
  ></SEO>
)
